import { Route, Routes } from "react-router-dom";

import NavBar from "./components/NavBar/navbar";
import { Container } from "react-bootstrap";
import { lazy, Suspense } from "react";
import LoadingSpinner from "./components/LoadingSpinner/spinner";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback, logErrorBoundary } from "./utils/error";

const Home = lazy(() => import("./components/Home/home"));

const App = () => {
	return (
		// wrapper that attempts to catch errors and render a fallback UI if something fails
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onError={logErrorBoundary}
			onReset={() => {
				// currently do nothing
				// basic recovery option - window.location.reload();
			}}
		>
			{/* Basic container that contains the body of the website */}
			<Container fluid className="px-1 py-3 body-container">
				{/* Navigation tool bar */}
				<NavBar />
				{/* Container that will be populated by content */}
				<Container className="px-1 site-container">
					{/* While loading, display a spinner animation */}
					<Suspense fallback={<LoadingSpinner />}>
						{/* When a link is selected from NavBar, load into that page */}
						<Routes>
							<Route path="/" element={<Home />} />
						</Routes>
					</Suspense>
				</Container>
			</Container>
		</ErrorBoundary>
	);
};

export default App;
